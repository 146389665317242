div[class*="navbar-"] .text {
	-webkit-transition: fill 0.3s ease-out;
	transition: fill 0.3s ease-out;
}

.appbar.navbar-dark .text {
	fill: #fff;
}

.appbar.navbar-light .text {
	fill: #4a4a4a;
}

.nav-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

@media screen and (max-width: 476px) {
	.container {
		margin: 0px;
		width: 100%;
	}
}

.full-height {
	height: 100vh;
}

.spaces-top {
	margin-top: 20px;
}

.container .row:not(:first-child) {
	margin-top: 5%;
}

.medias-wrapper {
  width: 100%;
}

.medias-wrapper .checkbox {
  margin-bottom: 30px;
  margin-right: 20px;
}

.format-input {
  max-width: 300px;
  justify-content: flex-start;
  display: flex;
  align-items: baseline;
}

.format-input .date-input {
  margin-left: 10px;
  width: 200px;
  font-weight: 600;
  color: #607b7b;
  font-size: 13px;
}

.form-media {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
}

.form-media.subjectareas {
  display: flex;
  flex-direction: column;
}

.subjectareas-wrapper {
  margin-left: 30px;
  width: 100%;
}

.subjectareas-wrapper .checkbox {
  margin-bottom: 10px;
}

.sub-subjectarea-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.fab-global {
  position: fixed;
  right: 5%;
  bottom: 5%;
}

.form > .row > div {
  margin-top: 10px;
}

.center-flex {
  display: flex;
  justify-content: center;
}

.ad-modal .select-wrapper {
  width: 100%;
}

.ad-modal .general-fields .eui-col:nth-child(1) .select-wrapper {
  z-index: 40;
}

.ad-modal .general-fields .eui-col:nth-child(2) .select-wrapper {
  z-index: 39;
}

.ad-modal .general-fields .eui-col:nth-child(3) .select-wrapper {
  z-index: 38;
}

.ad-modal .general-fields .eui-col:nth-child(4) .select-wrapper {
  z-index: 37;
}

.ad-modal
  .base-size-items.select-wrapper
  .eui-select-field-wrapper
  > .menu-wrapper
  > .eui-dropdown-menu {
  min-width: unset !important;
  max-width: 490px !important;
}

.form-group {
  margin-top: 15px;
}

.ad-modal .btn-chip {
  display: flex;
}

.clear-top {
  padding-top: 0px !important;
}

.space-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-modal-wrapper .modal-body {
  padding: 0px;
}

.modal-form {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.text-field-alt {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.text-field-alt,
.text-field-alt .txtarea {
  font-size: 13px;
  font-weight: 400;
  color: #4b5353;
}

.text-field-alt .txtarea {
  border-radius: 3px;
  padding: 7px;
  border: 1px solid #d3dbe7;
}

.text-field-alt > .select-label {
  font-weight: 300;
}

.text-field-alt.has-error .txtarea {
  border: 1px solid #de350b !important;
}

.text-field-alt.has-error .control-label {
  color: #de350b !important;
}

.course-details {
  display: flex;
  align-items: center;
}

.course-details > *:not(.serial-course) {
  margin-left: 10px;
  font-size: 10px;
}

.eui-file-upload.has-error > .eui-file-dropzone {
  border: 1px solid #de350b !important;
}

.serial-course {
  font-size: 0.8em;
  color: gray;
}

label.checkbox {
  margin: 0px;
  font-size: 14px;
}

.modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal .modal-footer .btn-side-container {
  flex-grow: 1;
}

.form-control.txtarea[disabled] {
  background: transparent;
  opacity: 0.4;
}

.metrics-container {
  text-align: end;
  font-size: 0.7em;
  color: #607b7b;
  display: flex;
  padding-left: 10px;
}

.title-modal-items {
  font-size: 20px !important;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.title-modal-items span {
  font-weight: 400;
}

.general-form {
  margin-top: 30px;
  padding: 10px;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 800px !important;
  }
}

@media screen and (max-width: 476px) {
  .modal .modal-footer {
    flex-direction: column;
    align-items: baseline;
  }

  .modal .modal-footer .btn-side-container {
    align-self: flex-end;
  }
}

.PopFilter_selectWrapper__1pl4g {
  margin-top: 0px !important;
  z-index: 0;
}

.PopFilter_selectWrapper__1pl4g:nth-child(1) {
  z-index: 30;
}

.PopFilter_selectWrapper__1pl4g:nth-child(2) {
  z-index: 29;
}

.PopFilter_selectWrapper__1pl4g:nth-child(3) {
  z-index: 28;
}

.PopFilter_selectWrapper__1pl4g:nth-child(4) {
  z-index: 27;
}

.PopFilter_selectWrapper__1pl4g:nth-child(5) {
  z-index: 26;
}

.PopFilter_popoverContent__1tPt7 {
  width: 350px;
}

.PopFilter_popoverContent__1tPt7 .PopFilter_eui-popover-content__bxISq,
.PopFilter_alignPopFilter__1_NAe {
  display: flex;
  flex-direction: column;
}

.floating-action-dropdown {
	width: 100px;
	min-height: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.ListCard_listContainer__2mAax {
  max-height: 75vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.01);
}
.ListCard_spaceLabelCourse__28TLi {
  margin-left: 10%;
}

.ListCard_titleCard__21PZr {
  text-transform: capitalize;
  font-weight: bolder;
}

table.table-list tbody {
  display: grid;
  height: 75vh;
  width: 50%;
  overflow: auto;
}

.eui-card {
  margin-bottom: 20px;
  -webkit-transition-property: box-shadow, -webkit-transform;
  transition-property: box-shadow, -webkit-transform;
  transition-property: transform, box-shadow;
  transition-property: transform, box-shadow, -webkit-transform;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  -webkit-animation-timing-function: ease;
          animation-timing-function: ease;
}

.eui-card:hover {
  cursor: pointer;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
}

.table-list > tbody > tr > td {
  width: 100vw;
}

.disabled {
  background: #f0f0f0 !important;
}
.card-description-wide {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.description-ids {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.header-text {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
}

.table-list .card {
  box-shadow: none;
  padding: 0px;
}

.card-content {
  display: flex;
  align-items: center;
}

.card-logo {
  margin-left: 0;
  border-radius: 5px;
  overflow: hidden;
  width: 300px;
  height: 100px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.06), 0 5px 25px rgba(0, 0, 0, 0.06);
}

.card-logo > img {
  max-width: 100%;
  object-fit: cover;
}

.table-list .card .card-tools {
  align-self: flex-start;
}

.card-content .card-text {
  flex-grow: 1;
  flex-basis: 100%;
  padding: 30px;
}

.card-status {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.alter-label {
  font-size: 9px !important;
}

.table-list .card .card-status .edit-details > span {
  margin-right: 5px;
}
.table-list .card .card-status .edit-details > span:hover {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}
.details {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 600;
  font-size: 0.9em;
}

.table-list .card .card-status > span {
  color: #4b5353;
  font-size: 13px;
  font-weight: 600;
}

.table-list .card .card-logo img {
  max-width: 100%;
}

.fab-global {
	position: fixed;
	right: 5%;
	bottom: 5%;
}

.modal-form > .row > div {
	margin-top: 10px;
}

.center-flex {
	display: flex;
	justify-content: center;
}

.text-field-alt,
.text-field-alt .txtarea {
	font-size: 13px;
	font-weight: 400;
	color: #4b5353;
}

.text-field-alt > .select-label {
	font-weight: 400;
}

.course-details {
	display: flex;
	align-items: center;
}

.course-details > span:not(.serial-course) {
	margin-left: 5px;
}

.serial-course {
	font-size: 0.8em;
	color: gray;
}

label.checkbox {
	margin: 0px;
	font-size: 14px;
}

.modal .modal-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modal .modal-footer .btn-side-container {
	flex-grow: 1;
}

.form-control.txtarea[disabled] {
	background: transparent;
	opacity: 0.4;
}

@media screen and (max-width: 476px) {
	.modal .modal-footer {
		flex-direction: column;
		align-items: baseline;
	}

	.modal .modal-footer .btn-side-container {
		align-self: flex-end;
	}
}

.flex {
	display: flex;
	flex-direction: column;
}

.floating-action-button {
	padding-top: 0px;
	line-height: 0;
}

.to-left {
	align-self: flex-start;
}

.to-center {
	align-self: center;
}

.to-right {
	align-self: flex-end;
}

.row-cont {
	display: flex;
	align-items: center;
	justify-content: center;
}

.dropdown .show.dropdown-menu {
	max-width: 400px;
}

