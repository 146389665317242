div[class*="navbar-"] .text {
	transition: fill 0.3s ease-out;
}

.appbar.navbar-dark .text {
	fill: #fff;
}

.appbar.navbar-light .text {
	fill: #4a4a4a;
}
