.nav-container {
	display: flex;
	justify-content: space-between;
	width: 100%;
}

@media screen and (max-width: 476px) {
	.container {
		margin: 0px;
		width: 100%;
	}
}
