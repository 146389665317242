.medias-wrapper {
  width: 100%;
}

.medias-wrapper .checkbox {
  margin-bottom: 30px;
  margin-right: 20px;
}

.format-input {
  max-width: 300px;
  justify-content: flex-start;
  display: flex;
  align-items: baseline;
}

.format-input .date-input {
  margin-left: 10px;
  width: 200px;
  font-weight: 600;
  color: #607b7b;
  font-size: 13px;
}

.form-media {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  position: relative;
}

.form-media.subjectareas {
  display: flex;
  flex-direction: column;
}

.subjectareas-wrapper {
  margin-left: 30px;
  width: 100%;
}

.subjectareas-wrapper .checkbox {
  margin-bottom: 10px;
}

.sub-subjectarea-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}
