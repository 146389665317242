.listContainer {
  max-height: 75vh;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.01);
}
.spaceLabelCourse {
  margin-left: 10%;
}

.titleCard {
  text-transform: capitalize;
  font-weight: bolder;
}
