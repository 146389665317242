.fab-global {
  position: fixed;
  right: 5%;
  bottom: 5%;
}

.form > .row > div {
  margin-top: 10px;
}

.center-flex {
  display: flex;
  justify-content: center;
}

.ad-modal .select-wrapper {
  width: 100%;
}

.ad-modal .general-fields .eui-col:nth-child(1) .select-wrapper {
  z-index: 40;
}

.ad-modal .general-fields .eui-col:nth-child(2) .select-wrapper {
  z-index: 39;
}

.ad-modal .general-fields .eui-col:nth-child(3) .select-wrapper {
  z-index: 38;
}

.ad-modal .general-fields .eui-col:nth-child(4) .select-wrapper {
  z-index: 37;
}

.ad-modal
  .base-size-items.select-wrapper
  .eui-select-field-wrapper
  > .menu-wrapper
  > .eui-dropdown-menu {
  min-width: unset !important;
  max-width: 490px !important;
}

.form-group {
  margin-top: 15px;
}

.ad-modal .btn-chip {
  display: flex;
}

.clear-top {
  padding-top: 0px !important;
}

.space-flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.form-modal-wrapper .modal-body {
  padding: 0px;
}

.modal-form {
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}

.text-field-alt {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.text-field-alt,
.text-field-alt .txtarea {
  font-size: 13px;
  font-weight: 400;
  color: #4b5353;
}

.text-field-alt .txtarea {
  border-radius: 3px;
  padding: 7px;
  border: 1px solid #d3dbe7;
}

.text-field-alt > .select-label {
  font-weight: 300;
}

.text-field-alt.has-error .txtarea {
  border: 1px solid #de350b !important;
}

.text-field-alt.has-error .control-label {
  color: #de350b !important;
}

.course-details {
  display: flex;
  align-items: center;
}

.course-details > *:not(.serial-course) {
  margin-left: 10px;
  font-size: 10px;
}

.eui-file-upload.has-error > .eui-file-dropzone {
  border: 1px solid #de350b !important;
}

.serial-course {
  font-size: 0.8em;
  color: gray;
}

label.checkbox {
  margin: 0px;
  font-size: 14px;
}

.modal .modal-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modal .modal-footer .btn-side-container {
  flex-grow: 1;
}

.form-control.txtarea[disabled] {
  background: transparent;
  opacity: 0.4;
}

.metrics-container {
  text-align: end;
  font-size: 0.7em;
  color: #607b7b;
  display: flex;
  padding-left: 10px;
}

.title-modal-items {
  font-size: 20px !important;
  font-weight: 700;
  display: flex;
  align-items: center;
}

.title-modal-items span {
  font-weight: 400;
}

.general-form {
  margin-top: 30px;
  padding: 10px;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 800px !important;
  }
}

@media screen and (max-width: 476px) {
  .modal .modal-footer {
    flex-direction: column;
    align-items: baseline;
  }

  .modal .modal-footer .btn-side-container {
    align-self: flex-end;
  }
}
