.fab-global {
	position: fixed;
	right: 5%;
	bottom: 5%;
}

.modal-form > .row > div {
	margin-top: 10px;
}

.center-flex {
	display: flex;
	justify-content: center;
}

.text-field-alt,
.text-field-alt .txtarea {
	font-size: 13px;
	font-weight: 400;
	color: #4b5353;
}

.text-field-alt > .select-label {
	font-weight: 400;
}

.course-details {
	display: flex;
	align-items: center;
}

.course-details > span:not(.serial-course) {
	margin-left: 5px;
}

.serial-course {
	font-size: 0.8em;
	color: gray;
}

label.checkbox {
	margin: 0px;
	font-size: 14px;
}

.modal .modal-footer {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.modal .modal-footer .btn-side-container {
	flex-grow: 1;
}

.form-control.txtarea[disabled] {
	background: transparent;
	opacity: 0.4;
}

@media screen and (max-width: 476px) {
	.modal .modal-footer {
		flex-direction: column;
		align-items: baseline;
	}

	.modal .modal-footer .btn-side-container {
		align-self: flex-end;
	}
}
