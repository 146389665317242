.full-height {
	height: 100vh;
}

.spaces-top {
	margin-top: 20px;
}

.container .row:not(:first-child) {
	margin-top: 5%;
}
