table.table-list tbody {
  display: grid;
  height: 75vh;
  width: 50%;
  overflow: auto;
}

.eui-card {
  margin-bottom: 20px;
  transition-property: transform, box-shadow;
  transition-duration: 0.2s;
  animation-timing-function: ease;
}

.eui-card:hover {
  cursor: pointer;
  box-shadow: 0 2px 30px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;
}

.table-list > tbody > tr > td {
  width: 100vw;
}

.disabled {
  background: #f0f0f0 !important;
}
.card-description-wide {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  align-items: center;
}

.description-ids {
  display: flex;
  justify-content: space-between;
  width: 200px;
}

.header-text {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 10px;
}

.table-list .card {
  box-shadow: none;
  padding: 0px;
}

.card-content {
  display: flex;
  align-items: center;
}

.card-logo {
  margin-left: 0;
  border-radius: 5px;
  overflow: hidden;
  width: 300px;
  height: 100px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.06), 0 5px 25px rgba(0, 0, 0, 0.06);
}

.card-logo > img {
  max-width: 100%;
  object-fit: cover;
}

.table-list .card .card-tools {
  align-self: flex-start;
}

.card-content .card-text {
  flex-grow: 1;
  flex-basis: 100%;
  padding: 30px;
}

.card-status {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.alter-label {
  font-size: 9px !important;
}

.table-list .card .card-status .edit-details > span {
  margin-right: 5px;
}
.table-list .card .card-status .edit-details > span:hover {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}
.details {
  color: rgba(0, 0, 0, 0.4);
  font-weight: 600;
  font-size: 0.9em;
}

.table-list .card .card-status > span {
  color: #4b5353;
  font-size: 13px;
  font-weight: 600;
}

.table-list .card .card-logo img {
  max-width: 100%;
}
