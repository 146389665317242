.selectWrapper {
  margin-top: 0px !important;
  z-index: 0;
}

.selectWrapper:nth-child(1) {
  z-index: 30;
}

.selectWrapper:nth-child(2) {
  z-index: 29;
}

.selectWrapper:nth-child(3) {
  z-index: 28;
}

.selectWrapper:nth-child(4) {
  z-index: 27;
}

.selectWrapper:nth-child(5) {
  z-index: 26;
}

.popoverContent {
  width: 350px;
}

.popoverContent .eui-popover-content,
.alignPopFilter {
  display: flex;
  flex-direction: column;
}
