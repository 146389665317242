.flex {
	display: flex;
	flex-direction: column;
}

.floating-action-button {
	padding-top: 0px;
	line-height: 0;
}

.to-left {
	align-self: flex-start;
}

.to-center {
	align-self: center;
}

.to-right {
	align-self: flex-end;
}

.row-cont {
	display: flex;
	align-items: center;
	justify-content: center;
}

.dropdown .show.dropdown-menu {
	max-width: 400px;
}
